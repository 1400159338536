import React from "react"
import theme from "theme"
import { Theme, Image, Text, Box, Section } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"services"} />
      <Helmet>
        <title>Our Services | Explore Our Services at The Trim Trend</title>
        <meta
          name={"description"}
          content={"Elevate Your Style, Experience Precision"}
        />
        <meta
          property={"og:title"}
          content={"Our Services | Explore Our Services at The Trim Trend"}
        />
        <meta
          property={"og:description"}
          content={"Elevate Your Style, Experience Precision"}
        />
        <meta
          property={"og:image"}
          content={"https://zivoliteplay.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://zivoliteplay.com/img/barber.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://zivoliteplay.com/img/barber.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://zivoliteplay.com/img/barber.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://zivoliteplay.com/img/barber.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://zivoliteplay.com/img/barber.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://zivoliteplay.com/img/barber.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        padding="80px 0 90px 0"
        background="--color-light"
        quarkly-title="Product-4"
      >
        <Override slot="SectionContent" align-items="center" />
        <Box
          min-width="100px"
          min-height="100px"
          display="block"
          grid-template-columns="repeat(3, 1fr)"
          grid-template-rows="auto"
          grid-gap="0 35px"
          md-grid-template-columns="1fr"
          md-grid-gap="40px 0"
          margin="0px 0px 0 0px"
          lg-margin="0px 0px 0 0px"
        >
          <Box
            min-width="100px"
            min-height="100px"
            display="flex"
            flex-direction="row"
            align-items="center"
            md-flex-direction="column"
          >
            <Image
              src="https://zivoliteplay.com/img/4.jpg"
              display="block"
              max-width="100%"
              margin="0px 0px 0 0px"
              height="500px"
              width="50%"
              object-fit="cover"
              sm-height="220px"
              md-width="100%"
              md-margin="0px 0px 50px 0px"
              sm-margin="0px 0px 35px 0px"
            />
            <Box
              min-width="100px"
              min-height="100px"
              padding="0px 80px 0px 80px"
              width="50%"
              lg-padding="0px 50px 0px 50px"
              md-width="100%"
              sm-padding="0px 0 0px 0"
            >
              <Text
                margin="0px 0px 15px 0px"
                font="normal 600 42px/1.2 --fontFamily-sans"
                text-align="left"
                lg-font="normal 600 36px/1.2 --fontFamily-sans"
              >
                Superior Grooming
              </Text>
              <Text
                margin="0px 0px 50px 0px"
                font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
                lg-margin="0px 0px 40px 0px"
                sm-margin="0px 0px 30px 0px"
              >
                At The Trim Trend, we believe in providing more than just a
                haircut, we offer a comprehensive grooming experience designed
                to elevate your style and enhance your personal grooming
                routine. Our services are crafted to meet the diverse needs of
                our clients, ensuring satisfaction and perfection with every
                visit.
              </Text>
            </Box>
          </Box>
        </Box>
      </Section>
      <Section
        padding="88px 0 88px 0"
        lg-padding="56px 0 56px 0"
        sm-padding="32px 0 32px 0"
        background="--color-light"
      >
        <Override slot="SectionContent" max-width="1220px" />
        <Box
          margin="0px 0px 10px 0px"
          padding="15px 15px 15px 15px"
          md-margin="0px 0px 40px 0px"
          lg-margin="0px 0px 56px 0px"
        >
          <Text
            margin="0px 0px 24px 0px"
            font="--headline2"
            color="--dark"
            text-align="center"
            md-font='normal 700 42px/1.2 "Source Sans Pro", sans-serif'
          >
            Our Signature Services
          </Text>
        </Box>
        <Box
          display="flex"
          margin="0px 0px 49px 0px"
          md-flex-direction="column"
          md-align-items="center"
          md-justify-content="center"
        >
          <Box
            width="100%"
            padding="48px 15px 15px 15px"
            display="flex"
            align-items="flex-start"
            justify-content="flex-start"
            border-style="solid"
            border-width="2px 0px 0px 0px"
            border-color="--color-lightD2"
            md-width="100%"
            md-border-width="0px"
            sm-align-items="center"
            sm-flex-direction="column"
            sm-justify-content="center"
            sm-padding="25px 15px 15px 15px"
            md-padding="33px 15px 15px 15px"
          >
            <Box
              padding="0px 0px 0px 0px"
              width="100%"
              sm-align-items="center"
              sm-display="flex"
              sm-flex-direction="column"
              sm-justify-content="center"
            >
              <Text
                margin="0px 0px 0px 0px"
                font="--lead"
                color="--darkL2"
                sm-text-align="center"
              >
                Classic Cuts: Traditional styles and modern trims, each cut
                tailored to reflect your personal style.
                <br />
                <br />
                Precision Fade: Achieve a seamless fade that blends effortlessly
                with your natural hair texture.
                <br />
                <br />
                Sharp Crew Cut: Ideal for a clean, low-maintenance look that
                stands out in any setting.
              </Text>
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          margin="0px 0px 49px 0px"
          md-flex-direction="column"
          md-align-items="center"
          md-justify-content="center"
        >
          <Box
            width="100%"
            padding="48px 15px 15px 15px"
            display="flex"
            align-items="flex-start"
            justify-content="flex-start"
            border-style="solid"
            border-width="2px 0px 0px 0px"
            border-color="--color-lightD2"
            md-width="100%"
            md-border-width="0px"
            sm-align-items="center"
            sm-flex-direction="column"
            sm-justify-content="center"
            sm-padding="25px 15px 15px 15px"
            md-padding="33px 15px 15px 15px"
          >
            <Box
              padding="0px 0px 0px 0px"
              width="100%"
              sm-align-items="center"
              sm-display="flex"
              sm-flex-direction="column"
              sm-justify-content="center"
            >
              <Text
                margin="0px 0px 0px 0px"
                font="--lead"
                color="--darkL2"
                sm-text-align="center"
              >
                Luxury Shaving: A traditional wet shave using premium lotions
                and soothing balms to ensure a close, comfortable shave.
                <br />
                <br />
                Hot Towel Shave: Relax with a hot towel prep followed by a
                meticulous razor shave.
                <br />
                <br />
                Beard Sculpting: Perfect the shape and style of your beard or
                mustache for a refined look.
              </Text>
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          margin="0px 0px 49px 0px"
          md-flex-direction="column"
          md-align-items="center"
          md-justify-content="center"
        >
          <Box
            width="100%"
            padding="48px 15px 15px 15px"
            display="flex"
            align-items="flex-start"
            justify-content="flex-start"
            border-style="solid"
            border-width="2px 0px 0px 0px"
            border-color="--color-lightD2"
            md-width="100%"
            md-border-width="0px"
            sm-align-items="center"
            sm-flex-direction="column"
            sm-justify-content="center"
            sm-padding="25px 15px 15px 15px"
            md-padding="33px 15px 15px 15px"
          >
            <Box
              padding="0px 0px 0px 0px"
              width="100%"
              sm-align-items="center"
              sm-display="flex"
              sm-flex-direction="column"
              sm-justify-content="center"
            >
              <Text
                margin="0px 0px 0px 0px"
                font="--lead"
                color="--darkL2"
                sm-text-align="center"
              >
                Styling & Finishing: Advanced styling techniques to finalize
                your look with a professional touch.
                <br />
                <br />
                Pompadour Styling: Sculpt your hair into a sleek, voluminous
                pompadour.
                <br />
                <br />
                Textured Styling: Add depth and movement for a more dynamic and
                casual appearance.
              </Text>
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          margin="0px 0px 49px 0px"
          md-flex-direction="column"
          md-align-items="center"
          md-justify-content="center"
        >
          <Box
            width="100%"
            padding="48px 15px 15px 15px"
            display="flex"
            align-items="flex-start"
            justify-content="flex-start"
            border-style="solid"
            border-width="2px 0px 0px 0px"
            border-color="--color-lightD2"
            md-width="100%"
            md-border-width="0px"
            sm-align-items="center"
            sm-flex-direction="column"
            sm-justify-content="center"
            sm-padding="25px 15px 15px 15px"
            md-padding="33px 15px 15px 15px"
          >
            <Box
              padding="0px 0px 0px 0px"
              width="100%"
              sm-align-items="center"
              sm-display="flex"
              sm-flex-direction="column"
              sm-justify-content="center"
            >
              <Text
                margin="0px 0px 0px 0px"
                font="--lead"
                color="--darkL2"
                sm-text-align="center"
              >
                Hair Treatment Services: Nourishing treatments to rejuvenate
                your scalp and hair health.
                <br />
                <br />
                Deep Conditioning: Restore hydration and shine with our
                intensive conditioning treatments.
                <br />
                <br />
                Scalp Therapy: Invigorating treatments to promote a healthy
                scalp and hair growth.
              </Text>
            </Box>
          </Box>
        </Box>
      </Section>
      <Section
        padding="90px 0 90px 0"
        sm-padding="60px 0 60px 0"
        background="--color-light"
        color="--dark"
        md-padding="60px 0 60px 0"
        quarkly-title="Advantages/Features-27"
      >
        <Override
          slot="SectionContent"
          max-width="1220px"
          md-align-items="center"
          sm-min-width="280px"
        />
        <Box
          border-radius="24px"
          margin="0px 0px 20px 0px"
          md-margin="0px 0px 10px 0px"
          width="80%"
        >
          <Text
            color="--greyD3"
            md-text-align="center"
            margin="0px 0px 0px 0px"
            font="--headline2"
            width="100%"
            letter-spacing="-1px"
            lg-width="100%"
            md-font="--headline3"
          >
            Discover More
          </Text>
        </Box>
        <Box lg-flex-wrap="wrap">
          <Text
            margin="20px 0px 40px 0px"
            color="--greyD2"
            font="--base"
            lg-text-align="left"
            md-text-align="center"
          >
            To fully appreciate what The Trim Trend has to offer, we invite you
            to visit us and explore the array of grooming delights we provide.
            Each service is a step toward perfection, designed to enhance your
            unique style. For a deeper understanding of our services or to book
            a personalized consultation, don't hesitate to contact us directly.
          </Text>
          <Box
            sm-margin="0px 0px 30px 0px"
            sm-width="100%"
            display="flex"
            flex-direction="column"
            justify-content="space-between"
            md-align-items="center"
          >
            <Text
              margin="0px 0px 18px 0px"
              color="--darkL2"
              font="--headline3"
              lg-text-align="left"
              md-font="--lead"
              md-text-align="center"
            >
              Your Style, Our Mastery – Experience the Art of Grooming
            </Text>
          </Box>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
